import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Login from 'components/Login';
import { login } from 'store/actions/auth';
import { resetPassword, setEmailSent } from 'store/actions/user';
import ForgotPasswordDialog from 'components/Login/ForgotPasswordDialog';

class LoginContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      username: '',
      password: '',
      dialogOpen: false,
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.handleForgotPasswordClick = this.handleForgotPasswordClick.bind(this);
    this.handleDialogSubmit = this.handleDialogSubmit.bind(this);
    this.handleDialogClose = this.handleDialogClose.bind(this);
  }

  handleForgotPasswordClick() {
    this.setState({ dialogOpen: true });
  }

  handleDialogSubmit() {
    const { username } = this.state;
    this.props.resetPassword(username);
  }

  handleDialogClose() {
    this.props.setEmailSent(false);
    this.setState({ dialogOpen: false });
  }

  onSubmit(event) {
    event.preventDefault();
    const { username, password } = this.state;
    this.props.login(username, password).then(() => {
      this.props.history.push({ pathname: '/', state: { transition: 'slide' } });
    });
  }

  onChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  render() {
    const { username, password, dialogOpen } = this.state;
    const { errors, emailSent, userError, isAuthenticating } = this.props;
    return (
      <div className="slide-out">
        <Login
          username={username}
          password={password}
          onSubmit={this.onSubmit}
          onChange={this.onChange}
          isAuthenticating={isAuthenticating}
          onForgotPasswordClick={this.handleForgotPasswordClick}
          errors={errors}
        />
        <ForgotPasswordDialog
          open={dialogOpen}
          username={username}
          emailSent={emailSent}
          onSubmit={this.handleDialogSubmit}
          onClose={this.handleDialogClose}
          onChange={this.onChange}
          errorMessage={userError.message}
        />
      </div>
    );
  }
}

LoginContainer.propTypes = {
  history: PropTypes.object.isRequired,
  login: PropTypes.func.isRequired,
  resetPassword: PropTypes.func.isRequired,
  setEmailSent: PropTypes.func.isRequired,
  errors: PropTypes.array.isRequired,
  isAuthenticating: PropTypes.bool.isRequired,
  emailSent: PropTypes.bool.isRequired,
  userError: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  errors: state.get.auth.errors,
  isAuthenticating: state.get.auth.isAuthenticating,
  emailSent: state.get.user.emailSent,
  userError: state.get.user.error,
});

export default connect(
  mapStateToProps,
  { login, resetPassword, setEmailSent }
)(LoginContainer);
