import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, withStyles } from '@material-ui/core';
import LoginForm from 'components/Login/LoginForm';
import logo from 'static/icons/inatur_logo.svg';

const style = theme => ({
  container: {
    position: 'absolute',
    height: '100%',
  },
  left: {
    background: 'rgba(253, 252, 249, 1)',
  },
  right: {
    background: theme.gradients.light,
  },
  content: {
    padding: '200px 80px 80px 80px',
    color: theme.colors.fontColor1,
  },
  divider: {
    border: 'none',
    borderBottom: `1px solid ${theme.colors.fontColor1}`,
    marginBottom: '3rem',
  },
  title: {
    marginBottom: theme.margins.small,
    fontFamily: theme.fonts.vollkorn,
    color: theme.colors.fontColor1,
    fontWeight: 'normal',
    fontSize: 24,
  },
  logo: {
    height: 40,
    width: 110,
    position: 'absolute',
    right: 30,
    top: 200 + 2 * theme.custom.cardHeightLarge,
  },
});

const Login = ({
  classes,
  onSubmit,
  onChange,
  errors,
  username,
  password,
  onForgotPasswordClick,
  isAuthenticating,
}) => (
  <Grid container spacing={0} className={classes.container}>
    <Grid item xs={12} sm={7} md={7} lg={7} className={classes.left}>
      <div className={classes.content}>
        <Typography variant="display1" gutterBottom className={classes.title}>
          INATUR FORHANDLERLØSNING
        </Typography>
        <hr className={classes.divider} />
        <Grid item xs={12} sm={12} md={8} lg={8}>
          <LoginForm
            onChange={onChange}
            onSubmit={onSubmit}
            onForgottenPasswordClick={onForgotPasswordClick}
            username={username}
            password={password}
            errors={errors}
            isAuthenticating={isAuthenticating}
          />
        </Grid>
      </div>
    </Grid>
    <Grid item xs={12} sm={5} md={5} lg={5} className={classes.right}>
      <div>
        <img className={classes.logo} src={logo} alt="logo" />
      </div>
    </Grid>
  </Grid>
);

Login.propTypes = {
  classes: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onForgotPasswordClick: PropTypes.func.isRequired,
  username: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
  errors: PropTypes.array.isRequired,
  isAuthenticating: PropTypes.bool.isRequired,
};

export default withStyles(style)(Login);
