import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import crossIcon from 'static/icons/cross.svg';
import Button from 'components/Common/Button';
import TextField from 'components/Common/TextField';
import Status from 'components/Common/Status';

const styles = theme => ({
  dialog: {
    backgroundColor: theme.colors.menuBackground,
    padding: '4em',
  },
  title: {
    fontFamily: theme.fonts.barlow,
    color: theme.colors.menuFontColor,
    fontSize: 20,
    marginBottom: theme.margins.medium,
    padding: 0,
  },
  button: {
    marginRight: '2em',
  },
  iconButton: {
    marginLeft: theme.margins.small,
  },
  dialogContent: {
    padding: 0,
    marginBottom: '3em',
  },
  dialogActions: {
    float: 'left',
    margin: 0,
  },
});

const ForgotPasswordDialog = ({
  open,
  username,
  onSubmit,
  onClose,
  onChange,
  classes,
  emailSent,
  errorMessage,
}) => (
  <Dialog open={open} onClose={onClose} PaperProps={{ className: classes.dialog }}>
    <DialogTitle disableTypography className={classes.title}>
      Er du sikker på at du vil tilbakestille passord?
      <IconButton onClick={onClose} className={classes.iconButton}>
        <img src={crossIcon} alt="" />
      </IconButton>
    </DialogTitle>
    <DialogContent className={classes.dialogContent}>
      <TextField label="E-post" value={username} name="username" onChange={onChange} />
      {errorMessage && <Typography color="error">{errorMessage}</Typography>}
    </DialogContent>
    {!emailSent ? (
      <DialogActions className={classes.dialogActions}>
        <Button
          className={classes.button}
          theme="primary"
          onClick={onSubmit}
          label="SEND LENKE På E-POST"
        />

        <Button theme="tetriary" onClick={onClose} label="AVBRYT" />
      </DialogActions>
    ) : (
      <Status
        message="SENDT - Sjekk innboks for å sette nytt passord"
        variant="success"
        positionText="left"
      />
    )}
  </Dialog>
);

ForgotPasswordDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  username: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  emailSent: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
};

export default withStyles(styles)(ForgotPasswordDialog);
